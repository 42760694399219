<template>
	<div class="streak-on-dashboard">
		<v-chip
			v-if="profileInfos && profileInfos.entreprise_id && profileInfos.entreprise_id.slug == 'demo'"
			class="mr-1"
			color="#686bdf"
			text-color="white"
		>
			<v-avatar left class="white streak"> 32 </v-avatar>
			<span class="font-weight-bold">Jours d'affilée</span>
			<v-icon right>mdi-star</v-icon>
		</v-chip>
		<template v-else>
			<v-chip v-if="streak" color="#686bdf" text-color="white" class="mr-1">
				<v-avatar left class="white streak">
					{{ streakNumber }}
				</v-avatar>
				<span class="font-weight-bold">Jour<span v-if="streakNumber > 1">s</span> d'affilée</span>
				<v-icon v-if="streakNumber >= 1" right>🔥</v-icon>
			</v-chip>
		</template>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { displayStreakNumber } from '@/utils/streak.js';

export default {
	name: 'StreakOnDashboard',
	computed: {
		...mapGetters('profile', { profileInfos: 'profileInfos' }),
		...mapGetters('streak', { streak: 'streak' }),
		streakNumber() {
			return displayStreakNumber(this.streak);
		},
	},
	async created() {
		if (this.streak == null && this.profileInfos._id) {
			this.$store.dispatch('streak/searchStreakByUserId', { userId: this.profileInfos._id });
		}
	},
};
</script>

<style lang="scss" scoped>
.streak-on-dashboard {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: center;
}

.streak {
	color: #686bdf;
	font-weight: bold;
}
</style>
